import React, { useEffect, useState } from 'react';
import {local} from './DataTable';


const DataTable = () => {

const [sortby, setsortby] = useState(null);
const [sortorder, setsorder] = useState('asc');

const sortData = (column ) => {
if(sortby === column){
    setsorder(sortorder === 'asc' ? 'desc' : 'asc')
}else{

    setsortby(column)
    setsorder('asc')

}
}
  const [data, setData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(local +'/api/data');
        if (response.ok) {
          const responseData = await response.json();
          setData(responseData);
        } else {
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Data Table</h2>
      <table>
        <thead>
          <tr>
           <th>ID</th>
           <th onClick={ () => sortData('dateField') }>Date</th>
            <th onClick={ () => sortData('nameField') }>Name</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Location</th>
            <th>More Details</th>

          </tr>
        </thead>
        <tbody>
          {data.sort(
          (a, b)=> {
              if(sortby){
                const aval = a[sortby]
                const bval = b[sortby]
                if(aval < bval){
                    return sortorder === 'asc' ? -1 : 1;
                }
                if(aval > bval){
                                    return sortorder === 'asc' ? 1 : -1;
                                }
              }
              return 0;
          }
          ).map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.dateField}</td>
              <td>{row.nameField}</td>
              <td>{row.phoneField}</td>
              <td>{row.addressField}</td>
              <td>{row.locationField}</td>
              <td>{row.moreDetails}</td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
