import React, { useEffect, useState } from 'react';
import { local } from './DataTable';
import { SamithisList } from './SamithisList';
const Bookings = (props) => {

const [sortby, setsortby] = useState('dateField');
const [sortorder, setsorder] = useState('asc');

const sortData = (column ) => {
if(sortby === column){
    setsorder(sortorder === 'asc' ? 'desc' : 'asc')
}else{

    setsortby(column)
    setsorder('asc')

}
}
  const [data, setData] = useState([]);

  function compareDates(date1, date2) {
    // Convert the date strings to Date objects
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    // Compare the dates
    if (d1 >= d2) {
      return true;
    } else {
      return false;
    }
  }

  function getCurrentDateAsString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  
  const currentDate = getCurrentDateAsString();

const getDelete = async (id) => {
   try {
    const response = await fetch(local +'/api/data/'+id, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },

          });

       if (response.ok) {
              // Success, handle the response
//              const newEntry = await response.json();

    window.location.reload()
            } else {
              // Error, handle the response
//              const errorData = await response.json();
            }
      }catch (error) {
             // Network error
             console.error('Error:', error.message);
           }
}
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(local +'/api/data');
        if (response.ok) {
       //   console.log(props.subdomain);
          const responseData = await response.json();
          const filteredData = responseData.filter((item) => item.samithiName === props.subdomain && compareDates(item.dateField, currentDate));
          
          setData(filteredData);
        } else {
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, [props.subdomain]);

  return (
    <div className="card-container">
      <div>
        <h1>Upcoming Events</h1>
      </div>
      <div>
        <a href='/'>Home</a>
      </div>
      {data.sort((a, b) => {
        if (sortby) {
          const aval = a[sortby];
          const bval = b[sortby];
          if (aval < bval) {
            return sortorder === 'asc' ? -1 : 1;
          }
          if (aval > bval) {
            return sortorder === 'asc' ? 1 : -1;
          }
        }
        return 0;
      }).map((row) => (
        <div key={row.id} className="card-row">
          <div className="card">
            <div className="card-body">
              <div className='card-header'>
                <div className="card-field">
                  <div className="card-value">
                    <span className="card-date">{row.dateField}</span>
                  </div>
                </div>
                <div className="card-field">
                  <div className="card-value">{row.nameField}</div>
                </div>
              </div>
              <div className="card-field">
                <div className="card-label">Phone</div>
                <div className="card-value">{row.phoneField}</div>
              </div>
              <div className="card-field">
                <div className="card-label">Address</div>
                <div className="card-value">{row.addressField}</div>
                <div className="card-value"><a target="_blank" href={row.locationField}>{row.locationField}</a></div>
              </div>
              <div className="card-field">
                <div className="card-label">More Details</div>
                <div className="card-value">{row.moreDetails}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

};

export default Bookings;
