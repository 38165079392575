import React from 'react';
import { SamithisList } from './SamithisList';


const SuccessDialog = (props) => {

  return (
    <div>
        <div className='headings'>
          <img src="favicon.ico" width={50} height={50} alt="swami emblem" />
        </div>
        <h1>Sri Sathya Sai Seva Organization, {SamithisList[props.subdomain]?.name ?? props.subdomain}</h1>
        <h2>Sairam.. <br/>Thank you for booking the Bhajan. <br/><br/><span className='green'>Successfully Registered</span>.</h2>
        <a className='link' href='/registration'>Book another</a>
        <br />
        <br />
        <a href="/" > Click here to go Home Page</a>
    </div>
  );
};

export default SuccessDialog;
