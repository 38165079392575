import React, { useEffect, useState } from 'react';

export const local = '';

const DataTable = (props) => {
  const [sortby, setsortby] = useState(null);
  const [sortorder, setsorder] = useState('asc');
  const [data, setData] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [editedData, setEditedData] = useState({}); // State to store the edited data for each row

  const sortData = (column) => {
    if (sortby === column) {
      setsorder(sortorder === 'asc' ? 'desc' : 'asc');
    } else {
      setsortby(column);
      setsorder('asc');
    }
  };

  const getDelete = async (id) => {
    const password = prompt('Enter your password to delete');
    try {
      const response = await fetch(local + '/api/data/' + id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'samithi': props.subdomain,
          'authorization': password,
        },
      });

      if (response.ok) {
        window.location.reload();
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleSave = async (id) => {
    const password = prompt('Enter your password to Edit');
    try {
      await fetch(local + '/api/data/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'samithi': props.subdomain,
          'authorization': password,
        },
        body: JSON.stringify(editedData),
      });

      setEditedRow(null);
      setEditedData({});
      fetchData();
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.subdomain]);

  useEffect(() => {
    // Sorting logic
    const sortedData = [...data];
    if (sortby) {
      sortedData.sort((a, b) => {
        const aValue = a[sortby];
        const bValue = b[sortby];

        if (sortorder === 'asc') {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }
    setData(sortedData);
  }, [props.subdomain, sortby, sortorder]);

  const fetchData = async () => {
    try {
      const response = await fetch(local + '/api/data');
      if (response.ok) {
        const responseData = await response.json();
        const filteredData = responseData.filter((item) => item.samithiName === props.subdomain);

        const sortedData = [...filteredData];
        if (sortby) {
          sortedData.sort((a, b) => {
            const aValue = a[sortby];
            const bValue = b[sortby];

            if (sortorder === 'asc') {
              return aValue.localeCompare(bValue);
            } else {
              return bValue.localeCompare(aValue);
            }
          });
        }

        setData(sortedData);
      } else {
        console.error('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  // Function to handle input changes in the editable fields
  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <div>
      <h2>Data Table</h2>
      <table>
        <thead>
          <tr>
            <th>Samithi</th>
            <th onClick={() => sortData('dateField')}>Date</th>
            <th onClick={() => sortData('nameField')}>Name</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Location</th>
            <th>More Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) =>
            editedRow === row.id ? (
              <tr key={row.id}>
                <td>
                  <input type="text" value={editedData.samithiName !== undefined ? editedData.samithiName : row.samithiName} onChange={(e) => handleInputChange(e, 'samithiName')} />
                </td>
                <td>
                  <input type="text" value={editedData.dateField !== undefined ? editedData.dateField : row.dateField} onChange={(e) => handleInputChange(e, 'dateField')} />
                </td>
                <td>
                  <input type="text" value={editedData.nameField !== undefined ? editedData.nameField : row.nameField} onChange={(e) => handleInputChange(e, 'nameField')} />
                </td>
                <td>
                  <input type="text" value={editedData.phoneField !== undefined ? editedData.phoneField : row.phoneField} onChange={(e) => handleInputChange(e, 'phoneField')} />
                </td>
                <td>
                  <input type="text" value={editedData.addressField !== undefined ? editedData.addressField : row.addressField} onChange={(e) => handleInputChange(e, 'addressField')} />
                </td>
                <td>
                  <input type="text" value={editedData.locationField !== undefined ? editedData.locationField : row.locationField} onChange={(e) => handleInputChange(e, 'locationField')} />
                </td>
                <td>
                  <input type="text" value={editedData.moreDetails !== undefined ? editedData.moreDetails : row.moreDetails} onChange={(e) => handleInputChange(e, 'moreDetails')} />
                </td>
                <td>
                  <button onClick={() => handleSave(row.id)}>Save</button>
                  <button onClick={() => setEditedRow(null)}>Cancel</button>
                </td>
              </tr>
            ) : (
              <tr key={row.id}>
                <td>{row.samithiName}</td>
                <td>{row.dateField}</td>
                <td>{row.nameField}</td>
                <td>{row.phoneField}</td>
                <td>{row.addressField}</td>
                <td>{row.locationField}</td>
                <td>{row.moreDetails}</td>
                <td>
                  <button onClick={() => setEditedRow(row.id)}>Edit</button>
                  <button onClick={() => getDelete(row.id)}>Cancel Bhajan</button>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
