import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from "date-fns";
import React, { useEffect, useState } from 'react';
import {local} from './DataTable';
import { SamithisList } from './SamithisList';
//import { useNavigate } from 'react-router-dom';

const SinglePageForm = (props) => {
//const history = useNavigate();


  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [formData, setFormData] = useState({
    samithiName: props.subdomain,dateField:"",nameField:"",phoneField:"",addressField:"",locationField:"",moreDetails:"",id:""
  });

   const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(local +'/api/data');
          if (response.ok) {
            const responseData = await response.json();
            setData(responseData);
          } else {
            console.error('Error:', response.status);
          }
        } catch (error) {
          console.error('Error:', error.message);
        }
      };

      fetchData();
    }, []);

//console.log("start Date",SamithisList[props.subdomain]?.startDate);
//console.log("end Date",SamithisList[props.subdomain]?.endDate);
  const formFields = [
    {
      id: 'dateField',
      label: 'Bhajan Date',
      required: true,
      type: 'date',
      min: SamithisList[props.subdomain]?.startDate,
      max: SamithisList[props.subdomain]?.endDate,
      blockedDates: data.filter((item) => item.samithiName === props.subdomain).map(item => parseISO(item.dateField,"yyyy-MM-dd")),
    },
    {
      id: 'nameField',
      label: 'Devotee Name',
      type: 'text',
      required: true,
    },
    {
      id: 'phoneField',
      label: 'Contact Phone number',
      type: 'tel',
      pattern: '[0-9]{1,10}',

      required: true,
    },
    {
      id: 'addressField',
      label: 'House Address',
      type: 'textarea',
      required: true,
    },
    {
      id: 'locationField',
      label: 'Google Map Location',
      type: 'text',
      required: false,
    },
    {
      id: 'moreDetails',
      label: 'More Details',
      type: 'textarea',
    },
  ];

  const fieldLabeNames = {
    dateField: "Date",
    nameField: "Name",
    phoneField: "Phone",
    addressField: "Address",
    locationField: "Location",
    moreDetails: "More Details",
  }


  const nextField = () => {
    const currentField = formFields[currentFieldIndex];
    const input = formData[currentField.id];
    const error = document.getElementById(`${currentField.id}Error`);

    if ((currentField.type === 'text' || currentField.type === 'textarea') && currentField.required && (!input || !input.trim())) {
      error.textContent = 'This field is required.';
      return;
    }

    if ((currentField.type === 'date') && currentField.required && (!input)) {
      error.textContent = 'This field is required.';
      return;
    }

    if (currentField.type === 'tel' && input && !input.match(currentField.pattern)) {
      error.textContent = 'Please enter a valid phone number.';
      return;
    }

    error.textContent = '';

    setCurrentFieldIndex((prevIndex) => prevIndex + 1);
  };

  const prevField = () => {
    setCurrentFieldIndex((prevIndex) => prevIndex - 1);
  };

  const showDetailsStep = () => {
    const submittedData = (
      <div className="submitted-data">
        <h3>Details:</h3>
        {Object.entries(formData).map(([key, value]) => (
          <p key={key}>
            <strong>{fieldLabeNames[key]}:</strong> {value.toString()}
          </p>
        ))}
      </div>
    );

    return <>
      <div id="detailsField">{submittedData}</div>
      <div className='buttons'>
        <button className="prev-button" onClick={prevField}>
          Previous
        </button>
        <button className="submit-button" onClick={submitForm}>
          Submit
        </button>
      </div>
    </>;
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dateField: date,
    }));
  };

/*  const isDateDisabled = (date) => {
    const currentField = formFields[currentFieldIndex];
    const blockedDates = currentField.blockedDates || [];
    const formattedDate = date.toISOString().split('T')[0];
    return blockedDates.includes(formattedDate);
  };*/

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const locationValue = `https://maps.google.com/?q=${latitude},${longitude}`;
          setFormData((prevFormData) => ({
            ...prevFormData,
            locationField: locationValue,
          }));
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

function parseISOToDate(dateStr) {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}


  const handleSubmit = async () => {
    try {
      formData.samithiName = props.subdomain;
      formData.dateField = parseISOToDate(formData.dateField);


      const response = await fetch(local + '/api/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        window.location.href = "/thankyou";
      } else {
        alert("Sorry, we could not capture your response, kindly try again or reach out to us.");
      }
    } catch (error) {
      // Network error
      console.error('Error:', error.message);
    }
  };

  const submitForm = () => {
    handleSubmit();
  };

//  const currentField = formFields[currentFieldIndex];

  return <>
    <div className="form-container">
      <div className='headings'>
        <img src="favicon.ico" width={50} height={50} alt = "emblem logo"/>
        <div>Registration form  <br></br> <small><i>{SamithisList[props.subdomain]?.name}</i></small></div>
      </div>
      {formFields.map((field, index) => (
        <div
          key={field.id}
          className={`form-field ${index === currentFieldIndex ? 'active' : ''}`}
          id={field.id}
        >
          <label htmlFor={field.id}>{field.label}:</label>
          {field.id === 'locationField' ? (
            <>
              <input
                type="text"
                id={field.id}
                required={field.required}
                value={formData[field.id] || ''}
                onChange={handleChange}
              />
             <button onClick={getLocation}>Get Current Location</button>
            </>
          ) : field.id === 'dateField' ? (
            <DatePicker
              selected={formData[field.id]}
              onChange={handleDateChange}
              required={field.required}
              minDate={new Date(field.min)}
              maxDate={new Date(field.max)}
              excludeDates={field.blockedDates}
              // filterDate={isDateDisabled}
              dateFormat="yyyy-MM-dd"
              className="date-picker"
            />
          ) : (
            field.type === "textarea" ?
            <textarea
              rows={10}
              cols={40}
              type={field.type}
              id={field.id}
              required={field.required}
              pattern={field.pattern}
              value={formData[field.id] || ''}
              onChange={handleChange}
            />
            :
            <input
              type={field.type}
              id={field.id}
              required={field.required}
              pattern={field.pattern}
              value={formData[field.id] || ''}
              onChange={handleChange}
            />
          )}
          <div className="error-message" id={`${field.id}Error`}></div>
        </div>
      ))}
      {currentFieldIndex < formFields.length ? (
        <div className='buttons'>
          <button
            className="prev-button"
            onClick={prevField}
            style={{ display: currentFieldIndex === 0 ? 'none' : 'block' }}
          >
            Previous
          </button>
          <button className="next-button" onClick={nextField}>
            Next
          </button>
        </div>
      ) : (
        showDetailsStep()
      )}
    </div>
  </>;
};

export default SinglePageForm;
