import React, { Suspense, useEffect, useState } from 'react';
import SinglePageForm from './SinglePageForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import DataTable from './DataTable';
import DisplayTable from './DisplayTable';
import SuccessDialog from './SuccessDialog';
import Bookings from './Bookings';

const App = () => {
  const [subdomain, setSubdomain] = useState('');

  useEffect(() => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    const subdomainValue = parts[0];
    //console.log(subdomainValue);
    setSubdomain(subdomainValue);
  }, []);

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home subdomain={subdomain} />} />
          <Route path="/registration" element={<SinglePageForm subdomain={subdomain} />} />
          <Route path="/admin" element={<DataTable subdomain={subdomain} />} />
          <Route path="/thankyou" element={<SuccessDialog subdomain={subdomain} />} />
          <Route path="/display" element={<DisplayTable subdomain={subdomain} />} />
          <Route path="/bookings" element={<Bookings subdomain={subdomain} />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
