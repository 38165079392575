export const SamithisList = {
localhost:{name: "Bhagyanagar Samithi",startDate: "2024-07-29",endDate: "2024-10-28"},
ssssobhagyanagar:{name: "Bhagyanagar Samithi",startDate: "2024-07-23",endDate: "2024-10-28"},
alwal: {name: "Alwal Samithi",startDate: "2024-07-23",endDate: "2024-10-28"},
begumpet: {name: "Begumpet Samithi",startDate: "2024-07-23",endDate: "2024-10-28"},
asraonagar: {name: "AS Rao Nagar Samithi",startDate: "2024-07-31",endDate: "2024-11-05"},
bhagyanagar: {name: "Bhagyanagar Samithi", startDate: "2024-06-02",endDate: "2024-09-08"},
bowenpally: {name: "Bowenpally Samithi", startDate: "2024-08-06",endDate: "2024-11-11"},
habsiguda: {name: "Habsiguda Samithi", startDate: "2024-07-13",endDate: "2024-11-02"},
jeedimetla: {name: "Jeedimetla Samithi",startDate: "2024-07-03",endDate: "2024-11-02"},
kukatpally: {name: "Kukatpally Samithi",startDate: "2024-06-29",endDate: "2024-11-02"},
kushaiguda: {name: "Kushaiguda Samithi",startDate: "2024-08-03",endDate: "2024-11-08"},
kompally: {name: "Kompally Samithi",startDate: "2024-07-20",endDate: "2024-10-25"},
jgdgutta: {name: "Jagadgiri Gutta Samithi",startDate: "2024-08-03",endDate: "2024-11-08"},
moosapet: {name: "Moosapet Samithi",startDate: "2024-08-06",endDate: "2024-11-11"},
meast: {name: "Malkajgiri East Samithi",startDate: "2024-08-03",endDate: "2024-11-08"},
mwest: {name: "Malkajgiri West Samithi",startDate: "2024-08-03",endDate: "2024-11-08"},
medipally: {name: "Medipally Samithi",startDate: "2024-07-23",endDate: "2024-10-28"},
nacharam: {name: "Nacharam Samithi",startDate: "2024-07-23",endDate: "2024-11-05"},
safilguda: {name: "Safilguda Samithi",startDate: "2024-07-27",endDate: "2024-11-06"},
sainikpuri: {name: "Sainikpuri Samithi",startDate: "2024-07-31",endDate: "2024-11-05"},
saket: {name: "Saket Samithi",startDate: "2024-08-03",endDate: "2024-11-02"}

};
